import React,{useState,useEffect,useRef} from 'react';
import {
  MinimizeIcon,
  MinimizeIcon2,
  DragIcon,
  RiseIcon
} from '../icons'
import DropDownMenu from './components/DropDownMenu'
import lang from '../lang.json'
import {fidID_Dict} from '../fidID_Dict'
const f16='16px'
const f14='14px'
const f12='12px'
const f10='10px'

function numberWithCommas(x) {
  if (x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
}
export default function IndustrySector(props){

  const [isShowCheckList,setisShowCheckList]=useState(false)
  const L=(key)=>{return lang[key]?(lang[key][props.lang.lang]||key):key}
  const getSelectArray=()=>{
    let folder=props.screenListFolder
    let arr=[]
    for(let i in folder)
      for(let j in folder[i].screenList)
        arr.push({value:folder[i].screenList[j].screenName ,screenID:folder[i].screenList[j].screenID,folderIndex:i,screenIndex:j})
    return arr
  }
  const countPass=()=>{
    let count=0
    for(let i of props.PassFailscreenResult)
      if(i.pass)
        count+=1
    return count
  }
  const calPassPercent=()=>{
    if(props.PassFailscreenResult.length===0)return 0
    let count=0
    for(let i of props.PassFailscreenResult)
      if(i.pass)
        count+=1
    return Math.round(count/props.PassFailscreenResult.length*100)
  }
  const findValue=(c,ci)=>{
    for(let i of props.PassFailCritiaList)
      if(i.criteriaID===c.criteriaID)
        return ((props.criteriaDict[i.criteriaID]&&props.criteriaDict[i.criteriaID].matchingType===1&&props.criteriaDict[i.criteriaID].valueType===8)||
            (props.criteriaDict[i.criteriaID]&&props.criteriaDict[i.criteriaID].matchingType===1&&props.criteriaDict[i.criteriaID].valueType===2)||
            (props.criteriaDict[i.criteriaID]&&props.criteriaDict[i.criteriaID].matchingType===1&&props.criteriaDict[i.criteriaID].valueType===9))?
            `${(i.minValue!==undefined&&i.minValue!=='')?`>${i.minValue} `:''}${(i.maxValue!==undefined&&i.maxValue!=='')?`<${i.maxValue}`:''}`
            :i.display
    return ''
  }
  if(props.isCollapse)
    return(
        <div className='dragcomponent' style={{display:'flex',flexDirection:'column',width:'100%',height:'100%'}}>
          <div  style={{display:'flex',flexDirection:'column',flex:1,width:'100%',backgroundColor: props.theme? 'rgba(15, 74, 78, 0.3)':'rgba(210, 223, 224, 0.3)',borderRadius: '10px',padding:'10px',boxSizing: 'border-box',justifyContent:'space-between',alignItems:'center'}}>
            <div style={{fontSize:f14,minHeight:22}}>{L("Stock Info.")}</div>
            <div onClick={props.handleCollapseComponent} className="mousehover">
              {props.isEndofRow?<MinimizeIcon theme={props.theme}/>:<MinimizeIcon2 theme={props.theme}/>}
            </div>
            <div className="drag-handle mousehover">
              <DragIcon theme={props.theme}/>
            </div>
          </div>
        </div>
    )
  return(
      <div className='dragcomponent' style={{display:'flex',flexDirection:'column',width:'100%',height:'100%',alignItems:'flex-end',overflow:'hidden'}}>
        <div style={{display:'flex',flexDirection:'column',flex:1,width:'100%',backgroundColor: props.theme? 'rgba(15, 74, 78, 0.3)':'rgba(210, 223, 224, 0.3)',borderRadius: '10px',padding:'10px',boxSizing: 'border-box'}}>

          <div style={{display:'flex',width:'100%',flexDirection:'row',justifyContent:'space-between',boxSizing:'border-box',marginBottom:'5px'}}>
            <div style={{display:'flex',flexDirection:'row',flex:1}}>
              <div onClick={()=>setisShowCheckList(false)} className='mousehover' style={{flex:1,textAlign:'center',fontSize:f16,opacity:isShowCheckList?.3:1}}>{L("Stock Information")}</div>
              <div style={{fontSize:f16}}>|</div>
              <div onClick={()=>setisShowCheckList(true)} className='mousehover' style={{flex:1,textAlign:'center',fontSize:f16,opacity:isShowCheckList?1:.3}}>{L("CheckList")}</div>
            </div>
            <div className='dragicons' style={{display:'flex',flexDirection:'row'}}>
              <div onClick={props.handleCollapseComponent} className="mousehover">
                {props.isEndofRow?<MinimizeIcon2 theme={props.theme}/>:<MinimizeIcon theme={props.theme}/>}
              </div>
              <div className="drag-handle mousehover" style={{marginLeft:15}}>
                <DragIcon theme={props.theme}/>
              </div>
            </div>
          </div>
          {isShowCheckList?
              <div style={{display:'flex',flexDirection:'column',width:'100%',flex:1,boxSizing: 'border-box',alignItems:'flex-start',padding:'5px'}}>
                <div style={{fontSize:f14,marginBottom:5}}>{L("Base your Checklist on this screen")}</div>
                <DropDownMenu
                    dropboxstyle={{backgroundColor:props.theme?'#294F4C':'#DEEEED'}}
                    itemstyle={{backgroundColor:props.theme?'#294F4C':'#DEEEED',display:'flex',alignItems:'center',color:props.theme?'white':'black',border:'none',minHeight:26,paddingLeft:20}}
                    value={(props.selectedPassFailScreen&&props.selectedPassFailScreen.value)||L('Please Select')}
                    data={getSelectArray()} onClick={props.handleselectedPassFailScreen}
                    style={{width:'100%',backgroundColor:props.theme?'#294F4C':' #DEEEED',fontSize:f14,fontWeight:500}}
                />
                <div style={{fontSize:f14,fontWeight:500,marginTop:20}}>{countPass()} of {props.PassFailscreenResult.length} ({calPassPercent()}%)</div>
                <div style={{fontSize:f14,fontWeight:300}}>{L("items meet the benchmark criteria")}</div>

                <div style={{fontSize:f14,position:'relative',display:'flex',flexDirection:'column',width:'100%',flex:1,backgroundColor: props.theme?'rgba(13, 87, 97, 0.2)':'#F7F9FB',borderRadius: '5px',marginTop:5}}>
                  <div style={{position:'absolute',top:0,bottom:0,left:0,right:0,overflowY:'scroll'}}>
                    {props.PassFailscreenResult.map((c,ci)=>
                        <div key={ci} style={{padding:10,display:'flex',flexDirection:'row',width:'100%',boxSizing:'border-box',justifyContent:'space-between',alignItems:'center',color:c.pass?(props.theme?"white":'black'):'#FF434F'}}>
                          <div style={{textAlign:'left'}}>
                            {props.criteriaDict[c.criteriaID]&&props.criteriaDict[c.criteriaID].criteriaName||'unknown'}
                            &nbsp;{findValue(c,ci)}
                          </div>
                          <div>{c.pass?'Pass':'Fail'}</div>
                        </div>
                    )}
                  </div>
                </div>
              </div>
              :<div style={{position:'relative',display:'flex',flexDirection:'column',width:'100%',flex:1,boxSizing: 'border-box',alignItems:'flex-start',padding:'5px'}}>
                <div style={{position:'absolute',top:0,bottom:0,left:0,right:0,display:'flex',flexDirection:'column',width:'100%',boxSizing:'border-box',overflow:'auto',justifyContent:'flex-start',alignItems:'flex-start'}}>

                  <div style={{display:'flex',flexDirection:'column',width:'calc(100% - 10px)',borderRadius: '5px',boxSizing: 'border-box',padding:10,marginTop:5,alignItems:'flex-start',fontWeight:600,fontSize:f14,backgroundColor:props.theme?'rgba(13, 87, 97, 0.2)':'rgba(255,255,255,.5)',boxSizing:'border-box'}}>
                    {props.data.stockCode}&nbsp;&nbsp;&nbsp;&nbsp;{props.data.stockCode&&props.HKstockListDict&&props.HKstockListDict[props.data.stockCode]&&props.HKstockListDict[props.data.stockCode].stockName}
                  </div>
                  <div style={{display:'flex',flexDirection:'column',width:'calc(100% - 10px)',borderRadius: '5px',boxSizing: 'border-box'}}>
                    {/* <div style={{fontSize:f14,width:'100%',boxSizing:'border-box',padding:5,fontWeight:600,marginTop:5}}>{L("Information")}</div>
                <div style={{width:'calc( 100% - 20px )',height:1,backgroundColor:props.theme?'white':'black',opacity:.2,boxSizing:'border-box',margin:'0 10px 0 10px'}}/>*/}

                    {
                      props.StockYearData.length > 0? <div style={{width:'calc( 100% - 20px )',boxSizing:'border-box',fontSize:f14,margin:10,display:'flex',flexDirection:'column',border:'0.5px solid #8C8C8C',}}>
                        <div  style={{width:'100%',boxSizing:'border-box',display:'flex',flexDirection:'row',borderBottom:'0.5px solid #8C8C8C'}}>
                          <div style={{flex:1,display:'flex',flexDirection:'column',borderRight:'0.5px solid #8C8C8C'}}>
                            <div>{L('Year')}</div>
                          </div>
                          <div style={{flex:1,display:'flex',flexDirection:'column',borderRight:'0.5px solid #8C8C8C'}}>
                            <div>{L('EPS')}</div>
                            <div>{L('(CS)')}</div>
                          </div>
                          <div style={{flex:2,display:'flex',flexDirection:'column'}}>
                            <div>{L('Price')}($)</div>
                            <div style={{width:'100%',display:'flex',flexDirection:'row'}}>
                              <div style={{flex:1}}>{L('High')}</div>
                              <div style={{flex:1}}>{L('Low')}</div>
                            </div>
                          </div>
                        </div>

                        {props.StockYearData.map((item,index)=>
                            <div key={index} style={{width:'100%',boxSizing:'border-box',display:'flex',flexDirection:'row',backgroundColor:index%2===0?(props.theme?'rgba(196, 196, 196, 0.1)':'rgba(196, 196, 196, 0.2)'):'transparent'}}>
                              <div style={{flex:1,display:'flex',flexDirection:'column'}}>
                                {item.year}
                              </div>
                              <div style={{flex:1,display:'flex',flexDirection:'column',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>

                                <div style={{width:50}}>{Math.round(item.eps*100)/100}</div>{item.eps>0&&<RiseIcon/>}
                              </div>
                              <div style={{flex:1,display:'flex',flexDirection:'column'}}>
                                {item.priceHigh!==undefined?Math.round(item.priceHigh*100)/100:''}
                              </div>
                              <div style={{flex:1,display:'flex',flexDirection:'column'}}>
                                {item.priceLow!==undefined?Math.round(item.priceLow*100)/100:''}
                              </div>
                            </div>
                        )}
                      </div>:<div style={{display: 'none'}}></div>

                    }




                    {
                      (props.StockYearData.length > 0&&props.StockYearData[0].peHigh===undefined)||props.StockYearData.length===0?
                          <div style={{display: 'none'}}></div>
                          :
                          <div style={{width:'calc( 100% - 20px )',boxSizing:'border-box',fontSize:f14,margin:10,marginTop:0,display:'flex',flexDirection:'column',border:'0.5px solid #8C8C8C',}}>

                            <div  style={{width:'100%',boxSizing:'border-box',display:'flex',flexDirection:'row',borderBottom:'0.5px solid #8C8C8C'}}>
                              <div style={{flex:1,display:'flex',flexDirection:'column',borderRight:'0.5px solid #8C8C8C'}}>
                                <div>{L('Year')}</div>
                              </div>

                              <div style={{flex:2,display:'flex',flexDirection:'column'}}>
                                <div>{L('PE')}</div>
                                <div style={{width:'100%',display:'flex',flexDirection:'row'}}>
                                  <div style={{flex:1}}>{L('High')}</div>
                                  <div style={{flex:1}}>{L('Low')}</div>
                                </div>
                              </div>
                            </div>

                            {props.StockYearData.map((item,index)=>
                                <div key={index} style={{width:'100%',boxSizing:'border-box',display:'flex',flexDirection:'row',backgroundColor:index%2===0?(props.theme?'rgba(196, 196, 196, 0.1)':'rgba(196, 196, 196, 0.2)'):'transparent'}}>
                                  <div style={{flex:1,display:'flex',flexDirection:'column'}}>
                                    {item.year}
                                  </div>

                                  <div style={{flex:1,display:'flex',flexDirection:'column'}}>
                                    {item.peHigh!==undefined?Math.round(item.peHigh*100)/100:''}
                                  </div>
                                  <div style={{flex:1,display:'flex',flexDirection:'column'}}>
                                    {item.peLow!==undefined?Math.round(item.peLow*100)/100:''}
                                  </div>
                                </div>
                            )}
                          </div>
                    }
                    {[138,2,44,126,135,64,117,115,114,111,112].map((field,field_index)=>
                        <div key={field_index} style={{fontSize:f14,fontWeight:400,width:'100%',boxSizing:'border-box',padding:'0 10px 0 10px',display:'flex',justifyContent:'space-between'}}>
                          <div style={{textAlign:'left'}}>{L(fidID_Dict[field].d)}</div>
                          {fidID_Dict[field].t==='Number'?
                              <div style={{
                                color:
                                    (props.data&&props.data.data)
                                        ?fidID_Dict[field].color
                                            ?(props.data.data[field]>=0?'#04D26F':'#FF3E3A')
                                            :(props.theme?'white':'black')
                                        :(props.theme?'white':'black')
                              }}>
                                {props.data&&props.data.data&&
                                    (fidID_Dict[field].isComma?
                                            numberWithCommas(props.data.data[field]):
                                            Math.round(props.data.data[field]*10000)/(fidID_Dict[field].percent?100:10000)
                                    )
                                }
                              </div>
                              :<div style={{}}>{props.data&&props.data.data&&props.data.data[field]||'-'}</div>}
                        </div>
                    )}
                  </div>

                  <div style={{display:'flex',flexDirection:'column',width:'calc(100% - 10px)',backgroundColor: props.theme?'rgba(13, 87, 97, 0.2)':'rgba(255, 255, 255, 0.3)',borderRadius: '5px',boxSizing: 'border-box',padding:10,marginTop:10}}>
                    <div style={{fontSize:f14,width:'100%',boxSizing:'border-box',padding:5,fontWeight:600,marginBottom:5}}>{L("Industry Group")}</div>
                    <div style={{fontSize:f14,fontWeight:400,width:'100%',boxSizing:'border-box',textAlign:'left',textDecoration:'underline'}}>
                      {props.data.industryName||'[Industry Name]'}
                    </div>
                    <div style={{fontSize:f14,fontWeight:400,width:'100%',boxSizing:'border-box',display:'flex',justifyContent:'space-between',textAlign:'left'}}>
                      <div>{L("Industry Code")}</div>
                      <div>{props.data.IndustryCode||'-'}</div>
                    </div>
                    <div style={{fontSize:f14,fontWeight:400,width:'100%',boxSizing:'border-box',display:'flex',justifyContent:'space-between',textAlign:'left'}}>
                      <div>{L("Total Number of Stocks")}</div>
                      <div>{props.data.totalNumberOfStocks||'-'}</div>
                    </div>
                    <div style={{fontSize:f14,fontWeight:400,width:'100%',boxSizing:'border-box',display:'flex',justifyContent:'space-between',textAlign:'left'}}>
                      <div>{L("Ranking")}</div>
                      <div>{props.data&&props.data.data&&props.data.data[137]||'-'}</div>
                    </div>
                    <div onClick={props.handleSelectIndustry} className='mousehover' style={{display:'flex',justifyContent:'center',alignItems:'center',height:29,width:'100%',boxSizing:'border-box',backgroundColor:props.theme?'#294F4C':'#DEEEED',marginTop:10,borderRadius:4,fontSize:f12,textAlign:'center'}}>
                      {L("View Stock in industry")}
                    </div>

                    <div style={{fontSize:f14,width:'100%',boxSizing:'border-box',padding:5,marginTop:5,fontWeight:600}}>{L("Sector")}</div>
                    <div style={{fontSize:f14,fontWeight:400,width:'100%',boxSizing:'border-box',textAlign:'left',textDecoration:'underline',textAlign:'left'}}>
                      {props.data.SectorName||'[Sector Name]'}
                    </div>
                    <div style={{fontSize:f14,fontWeight:400,width:'100%',boxSizing:'border-box',display:'flex',justifyContent:'space-between',textAlign:'left'}}>
                      <div>{L("Sector Code")}</div>
                      <div>{props.data.SectorCode||'-'}</div>
                    </div>
                    <div style={{fontSize:f14,fontWeight:400,width:'100%',boxSizing:'border-box',display:'flex',justifyContent:'space-between',textAlign:'left'}}>
                      <div>{L("Total Number of Stocks")}</div>
                      <div>{props.data.totalNumberOfStocks_Sector||'-'}</div>
                    </div>
                    <div style={{fontSize:f14,fontWeight:400,width:'100%',boxSizing:'border-box',display:'flex',justifyContent:'space-between',textAlign:'left'}}>
                      <div>{L("Ranking")}</div>
                      <div>{props.data&&props.data.data&&props.data.data[136]||'-'}</div>
                    </div>
                    <div onClick={props.handleSelectSector} className='mousehover' style={{display:'flex',justifyContent:'center',alignItems:'center',height:29,width:'100%',boxSizing:'border-box',backgroundColor:props.theme?'#294F4C':'#DEEEED',marginTop:10,borderRadius:4,fontSize:f12,textAlign:'center'}}>
                      {L("View Stock in Sector")}
                    </div>
                  </div>


                </div>

              </div>
          }
        </div>
      </div>
  )
}